<template>
  <div>
    <TableContainer>
      <template v-slot:header>
        <TableHeaderItem>
          Email
        </TableHeaderItem>
        <TableHeaderItem>
          Role
        </TableHeaderItem>
        <TableHeaderItem>
          Actions
        </TableHeaderItem>
      </template>

      <template v-slot:rows>
        <div class="tw-my-3"></div>
        <TableRow
          v-for="user in invited_users"
          :key="'invitee' + user.id"
          @click="toggleInvitedModal(user)"
        >
          <TableField>
            <div class="tw-inline-flex">
              <Avatar
                v-if="!loading"
                :username="`${user.email}`"
                :size="30"
                class="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-mr-2 tw-bg-gray-300 tw-rounded-full"
              ></Avatar>
              <a
                v-if="!loading"
                :href="`mailto:${user.email}`"
                class="tw-my-auto tw-text-sm tw-font-normal tw-text-gray-500 hover:tw-text-gray-700"
              >
                {{ user.email }}
              </a>
            </div>
          </TableField>
          <TableField>
            <p class="tw-text-sm tw-font-normal tw-text-gray-500">Member</p>
          </TableField>
          <!-- <TableField>
            <p class="tw-text-sm tw-font-normal tw-text-gray-500 hover:text-gray-70">{{ user.inviter_name}}</p>
          </TableField> -->
          <TableField>
            <a class="tw-text-sm tw-font-normal tw-text-red-500 hover:text-gray-70"
              >Revoke invite</a
            >
          </TableField>
        </TableRow>
      </template>
    </TableContainer>
    <SlideOutModal
      heading="Invited User Details"
      v-if="invited_modal"
      v-on:closemodal="toggleInvitedModal"
    >
      <InviteeEdit :user="clicked_invitee" @close="toggleInvitedModal" />
    </SlideOutModal>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import TableContainer from "@/components/ui/Table/TableContainer.vue";
import TableHeaderItem from "@/components/ui/Table/TableHeaderItem.vue";
import TableField from "@/components/ui/Table/TableField.vue";
import TableRow from "@/components/ui/Table/TableRow.vue";
import SlideOutModal from "@/components/ui/modals/SlideOutModal.vue";
import InviteeEdit from "@/components/Team/InviteeEdit.vue";

export default {
  name: "InvitedUserTable",
  props: ["invited_users", "loading"],
  components: {
    Avatar,
    TableContainer,
    TableHeaderItem,
    TableField,
    TableRow,
    InviteeEdit,
    SlideOutModal
  },
  data() {
    return {
      invited_modal: false,
      clicked_invitee: {}
    };
  },
  methods: {
    toggleInvitedModal(user = {}) {
      this.clicked_invitee = user;
      this.invited_modal = !this.invited_modal;
    }
  }
};
</script>

<style></style>
