<template>
  <div>
    <!-- <section class="tw-mb-12 lg:tw-flex lg:tw-items-center lg:tw-justify-between">
      <div class="tw-flex-1 tw-min-w-0">
        <h3 class="tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900">
          Team Members
        </h3>
        <p class="tw-max-w-2xl tw-mt-2 tw-text-lg tw-text-gray-500">
          Manage and invite users to your team
        </p>
      </div>
    </section> -->
    <section class="lg:tw-divide-y lg:tw-divide-gray-200 tw-divide-none">
      <div class="tw-flex tw-justify-between">
        <div class="tw-flex-1 tw-min-w-0 tw-my-6">
          <h3 class="tw-text-xl tw-font-medium tw-leading-5 tw-text-gray-700">
            My team
          </h3>
          <p class="tw-max-w-2xl tw-mt-2 tw-text-sm tw-text-gray-500">
              Manage and invite users to your team. Only active users are billed and can access the app. 
          </p>
        </div>

        <button
          @click="toggleInviteModal"
          type="button"
          class="tw-items-center tw-self-center tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-bg-orange-500 tw-rounded-md hover:tw-bg-orange-600"
        >
          Add user
        </button>
      </div>
      <!-- <UserCardList v-if="small_screen" :invited_users="invited_users" /> -->
      <TeamUsersTableFilters @updateQuery="updateQuery" />

      <UserTable :team_members="team_members" />

      <TablePagination
        :total_count="total_count"
        :showing_count="team_members.length"
        :offset="offset"
        :is_first="offset === 0"
        :is_last="team_members.length < limit"
        @pageup="pageUp"
        @pagedown="pageDown"
      />
      <div v-if="!small_screen" >
        <h3 class="tw-mt-10 tw-text-xl tw-font-medium tw-text-gray-700">
          Invited Users
        </h3>
        <p class="tw-max-w-2xl tw-mt-2 tw-text-sm tw-text-gray-500">
          These users are already invited to join the team, but have not yet created an account. If their invite has expired, you will need to revoke and invite them again.
        </p>
        <InvitedUserTable :invited_users="invited_users" />
      </div>
    </section>
    <SlideOutModal
      heading="Invite a team member"
      v-if="invite_modal"
      v-on:closemodal="toggleInviteModal"
    >
      <TeamInviteForm @invited="invitedAdd" />
    </SlideOutModal>
  </div>
</template>

<script>
// import UserCardList from "@/components/Team/UserCardList.vue";
import TeamInviteForm from "@/components/Team/TeamInviteForm.vue";
import UserTable from "@/components/Team/UsersTable.vue";
import InvitedUserTable from "@/components/Team/InvitedUserTable";
import SlideOutModal from "@/components/ui/modals/SlideOutModal";
import TeamUsersTableFilters from "@/components/Team/TeamUsersTableFilters.vue";
import TablePagination from "@/components/ui/Table/TablePagination.vue";

export default {
  name: "team-users",
  components: {
    UserTable,
    // UserCardList,
    TeamInviteForm,
    InvitedUserTable,
    SlideOutModal,
    TeamUsersTableFilters,
    TablePagination
},
  data() {
    return {
      tab: 1,
      invited_users: [],
      team_members: [],
      screen_width: window.innerWidth,
      invite_modal: false,
      isLoading: false,
      offset: 0,
      limit: 15,
      query: "",
      total_count: 0,
    };
  },
  computed: {
    small_screen() {
      return this.screen_width < 800;
    }
  },
  methods: {
    updateTab(val) {
      this.tab = val;
    },
    invitedAdd(e) {
      this.invited_users.push(e);
      this.toggleInviteModal();
    },
    toggleInviteModal() {
      this.invite_modal = !this.invite_modal;
    },
    updateQuery(newQuery) {
            this.query = newQuery;
            this.offset = 0;
            this.getUsers();
    },
    pageUp() {
        this.offset += this.limit;
        this.fetchIncidents();
    },
    pageDown() {
        if (this.offset === 0) return;
        let newOffset = this.offset - this.limit;
        this.offset = newOffset < 0 ? 0 : newOffset;
        this.fetchIncidents();
    },
    async getInvitedUsers() {
      try {
        let uri = process.env.VUE_APP_API_ROOT + "invitations/";
        var response = await this.$http.get(uri);
        this.invited_users = response.data.results;
        this.isLoading = false;
      } catch (e) {
        // handle the authentication error here
      }
    },
    getUsers() {
      if (this.isLoading) return;
      this.isLoading = true;

      let team_id = this.$store.getters["user/currentTeam"].id;
      
      this.$store
        .dispatch("getResource", `teams/${team_id}/users/?limit=${this.limit}&offset=${this.offset}&${this.query}`)
        .then(res => {
          this.team_members = res

          /* TODO: Swap the above assignment for below when API is updated to support pagination */

          // this.team_members = res.data.results
          // this.total_count = res.data.count
        }).catch(() => {}).finally(() => this.isLoading = false);
    }
  },
  created() {
    this.getInvitedUsers();
    this.getUsers();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screen_width = window.innerWidth;
    });
  }
};
</script>

<style></style>
