<template>
  <div class="tw-mb-6">
    <div class="tw-flex tw-mb-4">
      <TableSearchField
        class="my-auto"
        placeholder="Search incidents by name, description, and more ..."
        name="search"
        label="Search"
        :value="search"
        :hideLabel="true"
        @textchange="onChange"
      />
      <button
        class="tw-flex tw-justify-end tw-my-auto tw-ml-6 tw-text-sm hover:tw-text-gray-700"
        :class="[hasMoreOptions ? 'tw-text-gray-700' : 'tw-text-gray-500']"
        @click="toggleFilters"
      >
        <span class="tw-mr-2 tw-font-medium">Filters</span>
        <FontAwesomeIcon
          :icon="hasMoreOptions ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']"
          class="my-auto"
        />
      </button>
      <slot name="buttons"></slot>
    </div>

    <div
      class="tw-grid tw-items-center tw-gap-4 tw-mb-4 sm:tw-grid-cols-4 xl:tw-grid-cols-6"
      v-if="hasMoreOptions"
    >
      <FormMultiSelect
        @select="onChange"
        label="Role"
        name="role"
        :value="role"
        :options="['Admin', 'Member', 'Guest']"
      />
      <FormMultiSelect
        @select="onChange"
        label="Status"
        name="is_active"
        :value="is_active"
        :options="['Active', 'Inactive']"
      />
      <FormDateField
        class="sm:w-full"
        :value="created_at_gte"
        label="Joined since"
        name="created_at_gte"
        @change="onChange"
      />
      <FormDateField
        class="sm:w-full"
        label="Joined before"
        :value="created_at_lte"
        name="created_at_lte"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FormMultiSelect from "../ui/forms/FormMultiSelect";
import FormDateField from "../ui/forms/FormDateField";
import TableSearchField from "../ui/Table/TableSearchField.vue";

export default {
  name: "ReportFilters",
  components: {
    FormMultiSelect,
    FormDateField,
    FontAwesomeIcon,
    TableSearchField,
  },
  data() {
    return {
      search: undefined,
      is_active: undefined,
      role: undefined,
      created_at_gte: undefined,
      created_at_lte: undefined,

      hasMoreOptions: false,
    };
  },
  methods: {
    toggleFilters() {
      this.hasMoreOptions = !this.hasMoreOptions;
    },
    async filterUsers() {
      // gather names of fields to be filtered
      let filters = [
        "role",
        "search",
        "is_active",
        "created_at_gte",
        "created_at_lte"
      ];

      // array to store parts of query string
      let queryString = [];

      // build queryString parts
      filters.forEach(item => {
        let param = item
        let value = this[item];

        if (value !== undefined) {
            if (param === "is_active") {
            value = value.map(item => item == 'Active' ? true : false);
            }

            // do not add undefined values or empty arrays to queryString
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    value.forEach((elem, i) => {
                        queryString.push(`${param}=${value[i]}`);
                    });
                }
            } else {
                queryString.push(`${param}=${value}`);
            }
        }
      });

      // join query string and emit to parent
      queryString = queryString.join("&");

      this.$emit("updateQuery", queryString);
    },
    onChange(name, value) {
      this[name] = value;
      this.filterUsers();
    },
    mapToId(options, selections) {
      let selected_ids = [];
      options.map(item => {
        if (selections.includes(item.name)) {
          selected_ids.push(item.id);
        }
      });
      return selected_ids;
    }
  }
};
</script>
