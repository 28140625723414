<template lang="html">
  <div class="tw-overflow-y-auto">
    <h3 class="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Details</h3>
    <div class="tw-flex tw-space-x-4">
      <avatar
        v-if="!!user"
        :username="user.email"
        :size="30"
        class="tw-w-10 tw-h-10 tw-bg-gray-300 tw-rounded-full tw-flex-shrink-0"
      ></avatar>
      <h4>{{ user.email }}</h4>
    </div>
    <h3 class="tw-my-2 tw-text-base">Invited by <span>{{ user.inviter_name }}</span></h3>
    <button class="tw-border tw-rounded tw-border-red-500 tw-py-1 tw-px-2" type="button">
          <span class="tw-text-red-500">Revoke invite</span>
        </button>
    <div class="tw-my-8">
      <UserRoleSelect :value="role || 'Guest'" @change="changeRole" />
    </div>
    <p class="tw-text-sm tw-text-red-500">{{ error }}</p>
    <FormActions
          @submit="postForm"
          :loading="submitting"
          @cancelchanges="$emit('close')"
      />
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import UserRoleSelect from "./UserRoleSelect.vue";
import FormActions from "../../vue-prism/components/form/FormActions.vue";

export default {
  name: "InviteeEdit",
  components: {
    UserRoleSelect,
    Avatar,
    FormActions
},
  props: ["user"],
  data() {
    return {
      role: this.user.role,
      submitting: false,
      error: ""
    }
  },
  computed: {
    current_team() {
      return this.$store.getters['user/currentTeam'].id;
    },
  },
  methods: {
    changeRole(newRole) {
      this.role = newRole;
    },
    postForm() {
      if (this.submitting) return;

        this.error = "";
        this.submitting = true;

        this.$http
          .patch(
            process.env.VUE_APP_API_ROOT +
              "teams/" + this.current_team + "/invitations/" + this.user.id,
            { role: this.role }
          )
          .then(() => {
            this.$emit('close')
          })
          .catch(() => {
            // handle authentication and validation errors here
            this.error = "There's been an error, try again later"
          })
          .finally(() => { this.submitting = false });
    }
  }
};
</script>

<style lang="css" scoped></style>
